@font-face {
  font-family: NanumSquare;
  src: local(NanumSquare);
  src: url(https://static.app.mmtalk.kr/fonts/NanumSquareL.eot);
  src: url(https://static.app.mmtalk.kr/fonts/NanumSquareL.eot?#iefix)
      format("embedded-opentype"),
    url(https://static.app.mmtalk.kr/fonts/NanumSquareL.woff) format("woff"),
    url(https://static.app.mmtalk.kr/fonts/NanumSquareL.ttf) format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: NanumSquare;
  src: local(NanumSquare);
  src: url(https://static.app.mmtalk.kr/fonts/NanumSquare.eot);
  src: url(https://static.app.mmtalk.kr/fonts/NanumSquare.eot?#iefix)
      format("embedded-opentype"),
    url(https://static.app.mmtalk.kr/fonts/NanumSquare.woff) format("woff"),
    url(https://static.app.mmtalk.kr/fonts/NanumSquare.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: NanumSquare;
  src: local(NanumSquareB);
  src: url(https://static.app.mmtalk.kr/fonts/NanumSquareB.eot);
  src: url(https://static.app.mmtalk.kr/fonts/NanumSquareB.eot?#iefix)
      format("embedded-opentype"),
    url(https://static.app.mmtalk.kr/fonts/NanumSquareB.woff) format("woff"),
    url(https://static.app.mmtalk.kr/fonts/NanumSquareB.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: NanumSquare;
  src: local(NanumSquareEB);
  src: url(https://static.app.mmtalk.kr/fonts/NanumSquareEB.eot);
  src: url(https://static.app.mmtalk.kr/fonts/NanumSquareEB.eot?#iefix)
      format("embedded-opentype"),
    url(https://static.app.mmtalk.kr/fonts/NanumSquareEB.woff) format("woff"),
    url(https://static.app.mmtalk.kr/fonts/NanumSquareEB.ttf) format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: NanumSquareRound;
  src: local(NanumSquareRoundB);
  src: url(https://static.app.mmtalk.kr/fonts/NanumSquareRoundB.eot);
  src: url(https://static.app.mmtalk.kr/fonts/NanumSquareRoundB.eot?#iefix)
      format("embedded-opentype"),
    url(https://static.app.mmtalk.kr/fonts/NanumSquareRoundB.woff)
      format("woff"),
    url(https://static.app.mmtalk.kr/fonts/NanumSquareRoundB.ttf)
      format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: NanumGothic;
  src: local(NanumGothic);
  src: url(https://static.app.mmtalk.kr/fonts/NanumGothic.ttf)
      format("truetype"),
    url(https://static.app.mmtalk.kr/fonts/NanumGothic.otf) format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: NanumGothic;
  src: local(NanumGothicL);
  src: url(https://static.app.mmtalk.kr/fonts/NanumGothicLight.ttf)
      format("truetype"),
    url(https://static.app.mmtalk.kr/fonts/NanumGothicLight.otf)
      format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: NanumGothic;
  src: local(NanumGothicB);
  src: url(https://static.app.mmtalk.kr/fonts/NanumGothicBold.ttf)
      format("truetype"),
    url(https://static.app.mmtalk.kr/fonts/NanumGothicBold.otf)
      format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: NanumGothic;
  src: local(NanumGothicEB);
  src: url(https://static.app.mmtalk.kr/fonts/NanumGothicExtraBold.ttf)
      format("truetype"),
    url(https://static.app.mmtalk.kr/fonts/NanumGothicExtraBold.otf)
      format("opentype");
  font-weight: 800;
  font-style: normal;
}

html,
body,
#wrapper {
  width: 100%;
  height: 100%;
}
body {
  font-family: "NanumSquare";
  margin: 0;
  font-size: 14px;
  padding: 0;
  color: #262626;
  letter-spacing: -1px;
  line-height: 24px;
  word-break: keep-all;
  background-color: #fff;
}
p,
ul {
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
  outline: 0;
}
button {
  font-family: "NanumSquare";
  outline: 0;
}

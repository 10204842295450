.contents__modal {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 101;
  opacity: 0;
  transition: opacity 0.25s ease-in, width 0s linear 0.25s;
  &.on {
    width: 100%;
    opacity: 1;
    transition: opacity 0.35s ease, width 0s;
    > div {
      transform: translateY(-0);
    }
  }
  .alert {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    width: 225px;
    overflow: hidden;
    border-radius: 8px;
    text-align: center;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
    transform: translateY(20px);
    transition: all 0.4s cubic-bezier(0.25, 0.84, 0.44, 1);
    p {
      padding: 20px 0;
      font-size: 15px;
      line-height: 24px;
      letter-spacing: -0.4px;
      background: #ffffff;
      white-space: pre-wrap;
      color: #000000;
    }
    button {
      padding: 13px 0;
      font-weight: 800;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: -0.4px;
      color: #000000;
      background: #fae464;
      border: 0;
      outline: none;
      margin: 0;
    }
  }
}

.contentsWrap{
  overflow: hidden;
  text-align: center;
  .contents__title{
    padding: 50px 0 28px;
    font-family: 'NanumSquareRound';
    font-weight: 600;
    font-size: 26px;
    line-height: 30px;
    img{
      height: 30px;
      vertical-align: bottom;
      margin-left: 6px;
    }
  }
  .contents__img{
    display: flex;
    justify-content: center;
    .imgbox{
      position: relative;
      display: flex;
      &::before,
      &::after{
        content: '';
        position: absolute;
        top:0;
        z-index: -1;
        display: inline-block;
        width: 100%;
        height: 100%;
        background: #F8F8F8;
        border-radius: 100%;
      }
      &::before{
        left:-186px;
      }
      &::after{
        right:-186px;
      }
      img{
        width: 232px; 
        height: 232px;
      }
    }
  }
  .contents__desc{
    padding: 34px 0 32px;
    .subtit{
      font-weight: 600;
      font-size: 16px;
      line-height: 18px;
      color:#999;
    }
    .tit{
      font-weight: 600;
      font-size: 24px;
      line-height: 51px;
      color:#000;
    }
    .desc{
      font-size: 18px;
      line-height: 20px;
    }
    .onair{
      display: inline-block;
      padding: 3px 8px;
      margin-left: 4px;
      font-weight: 800;
      font-size: 14px;
      line-height: 16px;
      background: #FF5553;
      border-radius: 17px;
      color: #fff;
      vertical-align: bottom;
    }
  }
  .contents__alert{
    display: flex;
    justify-content: center;
    padding: 0 0 30px;
    margin: 0 30px;
    .btn__alert{
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      width: 170px;
      height: 45px;
      background: #FF5553;
      border-radius: 80px;
      border:0;
      letter-spacing: -1px;
      img{
        margin-right: 8px;
      }
      span{
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        color:#fff;
      }
    }
  }
}
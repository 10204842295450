.play__top{
  position: relative;
  z-index: 1;
  padding: 25px 20px;
  color: #fff;
  box-sizing: border-box;
  height: 200px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(196, 196, 196, 0) 100%);
  .play__title{
    padding-bottom: 10px;
    position: relative;
    font-family: 'NanumSquareRound';
    span{
      font-size: 22px;
      font-weight: 600;
      line-height: 30px;
    }
    img{
      margin-left: 4px;
      width: 40px;
      vertical-align: top;
    }
  }
  .play__desc{
    position: relative;
    margin-bottom: 15px;
    .play__desc__tit{
      font-size: 22px;
      font-weight: 600;
      line-height: 25px;
    }
    .play__desc__subtit{
      padding-bottom: 6px;
      font-size: 16px;
      font-weight: 600;
      line-height: 18px;
    }
  }
  
}
.closed_mall_wrapper {
  font-family: "NanumGothic";
  color: #222222;
  .closed_mall_main_text_area {
    margin-top: 47px;
    width: 100%;
    .closed_mall_main_text {
      font-weight: 800;
      font-size: 1.18rem;
      text-align: center;
      line-height: 1.3rem;
      margin-bottom: 13px;
    }
    .closed_mall_sub_text {
      font-weight: 700;
      font-size: 0.9rem;
      line-height: 1.2rem;
      text-align: center;
      color: #777777;
    }
  }
  .closed_mall_image {
    display: block;
    width: 40%;
    margin: 57px auto;
  }
  .closed_mall_info {
    color: #777777;
    line-height: 1.5rem;
    text-align: center;
    font-weight: 700;
    .closed_mall_info_refresh {
      font-size: 0.9rem;
    }
    .closed_mall_info_refresh_sub {
      font-size: 0.7rem;
    }
    .closed_mall_info_barcode {
      font-size: 0.75rem;
      margin-bottom: 45px;
    }
  }
  .closed_mall_btn_area {
    margin-top: 23px;
    .closed_mall_btn {
      display: block;
      border: none;
      outline: none;
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      border-radius: 50px;
      width: 60%;
      height: 42px;
      margin: 0 auto;
      background-color: #f49699;
      font-size: 0.8rem;
      font-weight: 700;
      color: white;

      &:not(:first-child) {
        margin-top: 12px;
      }
    }
  }
}

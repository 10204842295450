.sound__button{
  min-width: 195px;
  height: 42px;
  padding: 10px 13px;
  background-color: rgba(0,0,0,0.4);
  background-clip: padding-box;
  border-radius: 100px;
  border: 0.5px solid rgba(255,255,255,0.2);
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  cursor: pointer;
  box-sizing: border-box;
  i{
    background: url(https://static.app.mmtalk.kr/business-contents/live-common/images/sound__ico.svg);
    display: inline-block;
    background-repeat: no-repeat;
    width: 25px;
    height: 20px;
    margin-right: 7px;
    vertical-align: top;
  }
}
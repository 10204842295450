.bottom__comments__box{
  position: relative;
  &.comment__scroll button{
    bottom: 25px;
  }
  button{
    position: absolute;
    bottom: -100%;
    // bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    min-width: 130px;
    padding: 5px 0px;
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    background-color: #FF5553;
    // box-shadow: 0 5px 10px 0 rgb(0 0 0 / 20%);
    border: 0;
    border-radius: 12px;
    cursor: pointer;
    display: inline-block;
  }
  .bottom__comments{
    width: 80%;
    max-width: 400px;
    height: 170px;
    margin-bottom: 15px;
    overflow-y: scroll;
    -webkit-mask-image: linear-gradient(transparent, #fff 30px);
    mask-image: linear-gradient(transparent, #fff 30px);
    -ms-overflow-style: none; 
    touch-action: pan-y;
    user-select: none;
    -webkit-user-drag: none;
    &::-webkit-scrollbar{ 
      display:none; 
    }
    .bottom__comments_inner{
      .bottom__comment{
        padding-bottom: 6px;
        font-size: 14px;
        line-height: 20px;
        color: #fff;
        &:first-child{
          padding-top: 6px;
        }
        &:last-child{
          padding-bottom: 0;
        }
        strong{
          padding-right: 10px;
          font-weight: normal;
          opacity: 0.6;
        }
        span{
          word-break: break-all;
        }
      }
    }
  }
  
}
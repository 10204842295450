.bottom__banner {
  .bottom__banner__info {
    padding: 10px 12px;
    background-color: #fff;
  }
  .bottom__banner__name {
    font-size: 12px;
    line-height: 16px;
    padding-bottom: 5px;
  }
  .bottom__banner__price {
    font-size: 14px;
    font-weight: 800;
    font-size: 14px;
    line-height: 15px;
  }
  .bottom__banner__sale {
    color: #ff5553;
    padding-right: 17px;
    position: relative;
  }
  .bottom__banner__sale::after {
    content: "";
    position: absolute;
    top: 2px;
    right: 7px;
    display: inline-block;
    width: 1px;
    height: 12px;
    background: #f0f0f0;
  }
  a {
    display: flex;
    width: 236px;
    border-radius: 8px;
    overflow: hidden;
    color: inherit;
    background: #fff;
    img {
      width: 76px;
    }
  }
}

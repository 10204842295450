.popup__product{
  width: calc(50% - 7px);
  list-style: none;
  margin-bottom: 22px;
  a{
    display: flex;
    flex-direction: column;
    color: #fff;
    img{
      width: 100%;
      height: 100%;
      border-radius: 6px;
    }
  }
  .popup__product__price{
    padding: 12px 0 4px;
    .price__sale{
      font-weight: 800;
      font-size: 18px;
      line-height: 20px;
      color: #FF5553;
      margin-right: 13px;
      position: relative;
      &::after{
        content: '';
        position: absolute;
        top: 3px;
        right: -7px;
        width: 1px;
        height: 13px;
        background: #F0F0F0;
        opacity: 0.2;
      }
    }
    .price__real{
      position: relative;
      font-weight: 800;
      font-size: 18px;
      line-height: 20px;
      text-overflow: ellipsis;
      white-space: nowrap;
      
    }
  }
}
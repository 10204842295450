.play__popup{
  min-width: 360px;
  width: calc(100vh * 0.5223);
  background:rgba(0, 0, 0, 0.4);
  .popup__actv {
    .play__popup__dim{
      opacity: 1;
      z-index: 0;
    }
    .play__popup__contents{
      bottom: 0;
    }
  }
  .play__popup__dim{
    position: absolute;
    top:0;
    z-index: -1;
    display: inline-block;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    opacity: 0;
    transition: opacity 0.3s ease-in
  }
  .play__popup__contents{
    position: absolute;
    left: 0;
    right: 0;
    z-index: 100;
    bottom: -100%;
    height: 50%;
    background: #39393C;
    border-radius: 20px 20px 0px 0px;
    transition: bottom .45s cubic-bezier(0.250, 0.84, 0.44, 1);
    .popup__product__tit{
      position: relative;
      padding: 17px 0;
      border-bottom: 1px solid #464649;
      text-align: center;
      font-weight: 600;
      font-size: 16px;
      line-height: 18px;
      color:#fff;
      span{
        display: inline-block;
        margin-left: 4px;
      }
      button{
        position: absolute;
        right: 22px;
        width: 16px;
        height: 16px;
        padding: 0;
        border:0;
        background-color:transparent;
        background: url(https://static.app.mmtalk.kr/business-contents/live-common/images/play_popup_close.svg) no-repeat;
        cursor: pointer;
      }
    }
    .popup__products__list{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 20px 27px;
      overflow-y: scroll;
      height: calc(100% - 70px);
      
    }
  }
}
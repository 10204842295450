.play__btn {
  .play__btn__button{
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 60;
    width: 66px;
    height: 66px;
    border-radius: 50%;
    background-color: rgba(255,255,255,0.3);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border: none;
    cursor: pointer;
    // display: none;
    span{
      position: absolute;
      top: 18px;
      left: 23px; 
      width: 0px;
      height: 0px;
      border-top: 15px solid transparent;
      border-left: 25px solid white;
      border-bottom: 15px solid transparent;
    }
  }
}
.contentsWrap {
  overflow: hidden;
  .playWrap {
    min-width: 360px;
    min-height: -webkit-fill-available;
    max-width: calc(100vh * 0.565);
    width: 100%;
    height: 100vh;
    position: relative;
    margin: auto;
    overflow: hidden;
  }
  .videoWrap {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: calc(100% + 30px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    video {
      min-width: 360px;
      min-height: -webkit-fill-available;
      height: 100%;
      width: 100%;
      max-width: 100%;
      object-fit: cover;
    }
  }
  .play__bottom {
    position: absolute;
    bottom: 0;
    width: 101%;
    left: -0.5%;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.65) 0%,
      rgba(196, 196, 196, 0) 95%
    );
    .bottom__input {
      background: rgba(255, 255, 255, 0.1);
      border-radius: 40px;
      box-sizing: border-box;
      height: 0;
      opacity: 0;
      overflow: hidden;
      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 36px;
      }
      .input {
        padding: 9px 30px 9px 5px;
        width: calc(100% - 50px);
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
        color: #fff;
        background: transparent;
        border: 0;
        box-sizing: border-box;
        outline: none;
      }
      .input::-webkit-input-placeholder {
        color: #fff;
      }
      .input:-ms-input-placeholder {
        color: #fff;
      }
      .submit {
        width: 36px;
        height: 36px;
        border: 0;
        border-radius: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url(https://static.app.mmtalk.kr/business-contents/live-common/images/play_input_btn.svg);
        background-size: 36px;
        overflow: hidden;
        cursor: pointer;
        outline: none;
        color: transparent;
        box-sizing: border-box;
        display: inline-block;
      }
    }
    .bottom__btn {
      position: absolute;
      bottom: 22px;
      right: 20px;
      display: flex;
      flex-direction: column;
      button {
        width: 58px;
        height: 58px;
        border: 0;
        border-radius: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
      }
      .bottom__btn__comment {
        background-image: url(https://static.app.mmtalk.kr/business-contents/live-common/images/play_comment.svg);
        margin-bottom: 10px;
      }
      .bottom__btn__item {
        position: relative;
        background-image: url(https://static.app.mmtalk.kr/business-contents/live-common/images/play_item.svg);
        background-position-y: 10px;
        span {
          display: inline-block;
          position: absolute;
          top: -5px;
          right: -2px;
          width: 22px;
          background: #ff5553;
          border-radius: 100%;
          font-family: "NanumSquare";
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          text-indent: 0;
          color: #fff;
        }
      }
    }
  }

  .play__bottom.comment__actv {
    .bottom__input {
      padding: 7px 8px 7px 25px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      opacity: 1;
      height: auto;
    }
    .bottom__btn {
      bottom: 88px;
    }
  }
}

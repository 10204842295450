.warning__popup{
  position: absolute;
  top:24px;
  left:0;
  right:0;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  padding: 13px 16px;
  width: calc(100% - 40px);
  height: 62px;
  background: #FFFFFF;
  opacity: 0.96;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  box-sizing: border-box;
  opacity: 1;
  &.hide{
    opacity: 0;
    top:-50%;
    transform: translateY(-10px);
    transition: opacity 0.3s, transform 0.3s, top 0.3s ease 0.5s;
  }
  i{
    width: 30px;
    height: 30px;
    margin-right: 12px;
    background-image: url(https://static.app.mmtalk.kr/business-contents/live-common/images/bell_ico.svg);
    display: inline-block;
    vertical-align: middle;
  }
  span{
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.5px;
    display: inline-block;
    width: calc(100% - 42px);
    vertical-align: middle;
  }
  button{
    width: 16px;
    height: 16px;
    margin-left: 20px; 
    padding: 0;
    border: 0;
    background-color: transparent;
    background: url(https://static.app.mmtalk.kr/business-contents/live-common/images/play_popup_close_black.svg) no-repeat;
    cursor: pointer;
  }
}
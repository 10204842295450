
/** BEGIN CSS **/
@keyframes rotate-loading {
    0%  {transform: rotate(0deg);-ms-transform: rotate(0deg); -webkit-transform: rotate(0deg); -o-transform: rotate(0deg); -moz-transform: rotate(0deg);}
    100% {transform: rotate(360deg);-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); -o-transform: rotate(360deg); -moz-transform: rotate(360deg);}
}

@keyframes loading-text-opacity {
    0%  {opacity: 1}
    20% {opacity: 1}
    50% {opacity: 0}
    100%{opacity: 1}
}

.loading_container {
    position: absolute;
    top:50%;
    left:50%;
    transform: translate3d(-50%,-50%,0);
    height: 100px;
    width: 100px;
    border-radius: 100%;
}

.loading {
    position:absolute;
    height: 96px;
    width: 96px;
    transform: translate3d(-50%,-50%,0);
    border-radius: 100%;
    border: 2px solid transparent;
    border-color: transparent #fff transparent #FFF;
    -moz-animation: rotate-loading 1.5s linear 0s infinite normal;
    -moz-transform-origin: 50% 50%;
    -o-animation: rotate-loading 1.5s linear 0s infinite normal;
    -o-transform-origin: 50% 50%;
    -webkit-animation: rotate-loading 1.5s linear 0s infinite normal;
    -webkit-transform-origin: 50% 50%;
    animation: rotate-loading 1.5s linear 0s infinite normal;
    transform-origin: 50% 50%;
}

.loading_container .loading {
    border-color: transparent #ffd0be transparent #ffd0be;
    transition: all 0.5s ease-in-out;
}

.loading_text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%,-50%,0);
    animation: loading-text-opacity 1.3s linear 0s infinite normal;
    // font-family: "Helvetica Neue, "Helvetica", ""arial";
    color:#bd9c90;
    font-size: 1rem;
    font-weight: bold;
    opacity: 1;
    text-align: center;
    text-transform: uppercase;
    width: 100px;
}
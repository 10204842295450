.contentsWrap{
  overflow: hidden;
  position: relative;
  .playWrap{
    min-width: 360px;
    min-height: -webkit-fill-available;
    max-width: calc(100vh * 0.565);
    width: 100%;
    height: 100vh;
    position: relative;
    margin: auto;
    overflow: hidden;
    &.controller__hide {
      .play__bottom{
        bottom: -100%;
      } 
      .controls{
        opacity: 1;
      }
    }
  }
  .videoWrap{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: calc(100% + 30px);
    .videoBox{
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      video{
        min-width: 360px;
        min-height: -webkit-fill-available;
        height: 100%;
        width: 100%;
        max-width: 100%;
        object-fit: cover;
      }
    }
    .controls{
      position: absolute;
      left: 0;
      bottom: 40px;
      z-index: 100;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      margin-top: 5px;
      padding: 20px 0;
      border-radius: 7px;
      opacity: 0;
      input{
        width: calc(100% - 105px);
        margin-right: 10px;
        height: 10px;
        border: none;
        border-radius: 3px;
        vertical-align: middle;
        border: 0;
        -webkit-appearance: none;
        overflow: hidden;
        cursor: pointer;
        background: #878584;
        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          width: 10px;
          height: 12px;
          background: white;
          box-shadow: -100vw 0 0 100vw #FF5553;
          border-left: 1px solid #FF5553;
          cursor: pointer;
        }
      }
      .progress__time{
        display: inline-block;
        min-width: 50px;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: -0.2px;
        color: #fff;
        // text-shadow: 0 1px 0 rgb(0 0 0 / 10%);
      }
    }
  }
  .play__bottom{
    position: absolute;
    bottom: 0;
    width: 101%;
    left:-0.5%;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.65) 0%, rgba(196, 196, 196, 0) 95%);
    .bottom__btn{
      position: absolute;
      bottom: 22px;
      right: 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      button{
        width: 58px;
        height: 58px;
        border: 0;
        border-radius: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
      }
      .bottom__btn__comment{
        background-image: url(https://static.app.mmtalk.kr/business-contents/live-common/images/play_comment.svg);
        margin-bottom: 10px;
      }
      .bottom__btn__speed{
        color:#fff;
        font-weight: bold;
        font-size: 18px;
        width: 58px;
        height: 58px;
        margin: 0 0 5px;
        // background:none;
      }
      .bottom__btn__item{
        position: relative;
        background-image: url(https://static.app.mmtalk.kr/business-contents/live-common/images/play_item.svg);
        background-position-y: 10px;
        span{
          display: inline-block;
          position: absolute;
          top: -5px;
          right: -2px;
          width: 22px;
          background: #FF5553;
          border-radius: 100%;
          font-family: 'NanumSquare';
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          text-indent: 0;
          color:#fff;
        }
      }
    }
  }
  .play__btn {
    .play__btn__button{
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 60;
      width: 66px;
      height: 66px;
      border-radius: 50%;
      background-color: rgba(255,255,255,0.3);
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      border: none;
      cursor: pointer;
      // display: none;
      span{
        position: absolute;
        top: 18px;
        left: 23px; 
        width: 0px;
        height: 0px;
        border-top: 15px solid transparent;
        border-left: 25px solid white;
        border-bottom: 15px solid transparent;
      }
    }
  }
  .notice__content{
    position: absolute;
    top:0;
    left:0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    color: #fff;
    background-color: rgba(0,0,0,0.5);
    display: none;
    div:first-child{
      margin-bottom: 15px;
    }
    div:nth-child(2){
      padding: 6px 17px 5px 16px;
      background-color: #fff;
      border-radius: 20px;
      color: #222;
    }
  }
}
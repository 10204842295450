.contents__banner{
  display: flex;
  align-items: center;
  margin: 30px 30px 40px;
  padding: 10px;
  background: #FAFAFA;
  border: 1px solid #F0F0F0;
  border-radius: 6px;
  box-sizing: border-box;
  img{
    margin-right: 14px;
    width: 56px;
  }
  .banner__title{
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    overflow: hidden;
    p{
      margin-bottom: 6px;
      font-weight: 600;
    }
    *{
      overflow: hidden;
      text-overflow:ellipsis;
      white-space:nowrap;
    }
  }
}
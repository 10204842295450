.host {
  display: flex;
  &.vertical {
    flex-direction: column;
    height: 100vh;
    .videoCont {
      width: 100%;
      height: 55vh;
      min-height: unset;
      max-width: unset;
      .videoWrap {
        height: 100%;
        margin: 0 auto;
        text-align: center;
        video {
          width: fit-content !important;
        }
      }
    }
    .commentCont {
      height: 45vh;
      .bottom__comments__box {
        .bottom__comment {
          font-size: 30px;
          line-height: 1.3;
        }
      }
    }
  }
  .vertical_button {
    position: absolute;
    border: none;
    outline: none;
    top: 20px;
    right: 20px;
    padding: 10px 10px;
    background-color: #ff5653;
    font-weight: 600;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    z-index: 1000;
  }
  .videoCont {
    min-width: 360px;
    min-height: -webkit-fill-available;
    max-width: calc(100vh * 0.765);
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    .videoWrap {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: calc(100% + 30px);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      video {
        min-width: 360px;
        min-height: -webkit-fill-available;
        height: 100%;
        width: 100%;
        max-width: 100%;
        object-fit: cover;
      }
    }
  }
  .commentCont {
    background-color: black;
    width: 100%;
    padding-left: 10px;
    overflow: scroll;
    height: 100vh;
    .hiddenArea {
      width: 100%;
      height: 15px;
    }
    .user__count {
      color: #fff;
    }
    .bottom__comments__box {
      position: relative;
      .bottom__comment {
        padding-bottom: 6px;
        font-size: 14px;
        line-height: 20px;
        color: #fff;
        &:first-child {
          padding-top: 6px;
        }
        &:last-child {
          padding-bottom: 0;
        }
        strong {
          padding-right: 10px;
          font-weight: normal;
          opacity: 0.6;
        }
        span {
          word-break: break-all;
        }
      }
    }
  }
}
